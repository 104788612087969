import {handleErrors } from './HttpService';
import md5 from 'md5';

export function AccountListService(queryParams = null) {
	const url = process.env.REACT_APP_NEW_FSR_API;
	console.log(md5(Math.random()))
	const factory = fetch(url, {
		method: 'POST',
		headers: {
			transactionId:md5(Math.random()),
			jjcc_region:"DEV_NA",
			api_name:"userAccounts",
			authorization:
			JSON.parse(sessionStorage.getItem('fsrToken')).access_token,
			
		},
		body:JSON.stringify(queryParams)
	})
		.then(res => res.json())
		.then(data => data)
		.catch(error => handleErrors(error));

	return factory;
}
